
@font-face {
	font-family: "Meri";
	src: url(/static/media/MeriendaOne-Regular.362e8c6e.ttf);
}

@font-face {
	font-family: "Marc";
	src: url(/static/media/MarcellusSC-Regular.110e1e10.ttf);
}


@font-face {
	font-family: "DoLight";
	src: url(/static/media/Dosis-Light.cead2bda.ttf);
}

@font-face {
	font-family: "Do";
	src: url(/static/media/Dosis-Regular.808d3433.ttf);
}

@font-face {
	font-family: "DoMedium";
	src: url(/static/media/Dosis-Medium.3f897d69.ttf);
}

@font-face {
	font-family: "DoSemiBold";
	src: url(/static/media/Dosis-SemiBold.050d8579.ttf);
}

@font-face {
	font-family: "SP";
	src: url(/static/media/SourceSansPro-Regular.c1678b46.ttf);
}

@font-face {
	font-family: "SPLight";
	src: url(/static/media/SourceSansPro-Light.81cd217e.ttf);
}

@font-face {
	font-family: "SPSemi";
	src: url(/static/media/SourceSansPro-SemiBold.83476a89.ttf);
}

body{
    color: #000;
    font-family: monospace;
    overflow-x: hidden;
}
.transition-50 {
    transition: all 50ms ease-in-out;
}

.transition-25 {
    transition: all 25ms ease-in-out;
}

.transition-100 {
    transition: all 100ms ease-in-out;
}

.transition-200 {
    transition: all 200ms ease-in-out;
}

.transition-300 {
    transition: all 300ms ease-in-out;
}

.transition-500 {
    transition: all 500ms ease-in-out;
}
.transition-800 {
    transition: all 800ms ease-in-out;
}
.transition-1000 {
    transition: all 1000ms ease-in-out;
}

.transition-250-linear {
    transition: all 500ms linear;
}

.transition-500-linear {
    transition: all 500ms linear;
}

.transition-1000-linear {
    transition: all 1000ms linear;
}


.transition-900 {
    transition: all 900ms ease-in-out;
}


.transition-1500 {
    transition: all 1500ms ease-in-out;
}
.no-underline-hover{
    outline: none;
}

.no-underline-hover:hover{
    text-decoration: none;
}

.center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btn-top{
    margin: 20px 15px 0 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 25px;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
}

.map-params{
    border: 4px solid;
    position: relative;
    margin: 20px auto 0 auto;
}
.unique-map-area{
    position: absolute;
    border: 0px solid #ddd;
    font-size: 7px;
    text-align: center;
}

.unique-map-area:hover .hover-unique-area {
    display: block;
}

.hover-unique-area{
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    border: 2px solid;
    display: none;
    z-index: 2;
    font-size: 12px;
}

.btn-creation {
    background-color: #000;
    color: #fff;
    margin: auto;
    padding: 45px 20px;
    min-width: 300px;
    margin-top: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    border: 2px solid #000;
}

.btn-creation:hover {
    background-color: #fff;
    color: #000;
    margin: auto;
    padding: 45px 20px;
    min-width: 300px;
    margin-top: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    border: 2px solid rgb(93, 93, 93);
}



.start-workout{
    font-size: 5vw;
    line-height: 6.5vw;
    cursor: pointer;
    font-weight: 800;
    letter-spacing: 0.2em;
    color: #fff;
}

.close-btn-work{
    position: fixed;
    z-index: 50000;
    height: 20px;
    width: auto;
    cursor: pointer;
    top: 20px;
    right: 30px;
}

.desc-workout{
    text-transform: unset;
    font-size: 3vw;
    line-height: 4vw;
    padding: 0 100px;
    letter-spacing: 0;
    margin-top: 50px;
    font-weight: 200;
}

.hover-start:hover{
    text-decoration: underline;
}

.btn-desc-choose{
    position: fixed;
    text-align: center;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    min-width: 200px;
    font-size: 20px;
    z-index: 1000;
    cursor: pointer;
    font-weight: 100;
    text-decoration: underline;
    color: #fff;
}

.panel-choose{
    position: fixed;
    width: 400px;
    right: -400px;
    top: 0;
    background-color: #fff;
    z-index: 25010;
    padding: 20px;
}

.panel-choose-open{
    position: fixed;
    width: 400px;
    right: 0px;
    top: 0;
    background-color: #fff;
    z-index: 25010;
    padding: 20px;
}

.back-open-b{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.294);
    opacity: 1;
    z-index: 25000;
}

.back-open{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    z-index: 25000;
}
.back-close{
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
    z-index: -1;
}
.title-choose{
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}

.box-work-select{
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 3px;
    background-color: #000;
}
.box-work-unselect{
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 3px;
    background-color: #fff;
}

.close-btn-choose{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: underline;
    cursor: pointer;
}

.card-player{
    background-color: #fafafa;
    box-shadow: 1px 2px 5px rgb(182, 182, 182);
    padding: 15px 20px;
}

.change-car{
    margin-bottom: 50px;
}
.change-car .carousel.carousel-slider .control-arrow {
    padding: 0;
}
.change-car .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
}
.change-car .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
}
.change-car .carousel .slider-wrapper.axis-horizontal .slider .slide {
    padding: 0 5px 5px 5px;
}
.change-car .carousel .slide {
    background-color: #fff;
}
.change-car .carousel .carousel-status {
    text-shadow: unset !important;
    top: 10px !important;
    right: 10px !important;
    font-weight: bold !important;
    color: #000  !important;
}
.select-yam{
    width: 60px;
    border: 0;
    text-align: right;
    background-color: #ffffff00;
}

@media screen and (max-width: 768px) {
    .start-workout{
        font-size: 7vw;
        line-height: 8.5vw;
        cursor: pointer;
        font-weight: 800;
        letter-spacing: 0.2em;
        color: #fff;
    }

    .no-padding-mobile{
        padding: 0 !important;
    }
    .desc-workout{
        text-transform: unset;
        font-size: 4.5vw;
        line-height: 6.5vw;
        letter-spacing: 0;
        padding: 0 0px;
        margin-top: 40px;
        font-weight: 200;
    }
    .btn-desc-choose{
        width: 100%;
    }
    .panel-choose-open{
        width: 100%;
        right: 0;
    }
    .panel-choose{
        width: 100%;
        right: -100%;
    }

    .close-btn-choose{
        bottom: 30px;
    }
}


.scroll-small-black::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 3px #f3f3f3;
	background-color: #ffffff;
}

.scroll-small-black::-webkit-scrollbar
{
	width: 3px;
    background-color: #ffffff;
}

.scroll-small-black::-webkit-scrollbar-thumb
{
	background-color: #000;
}

.row {
    margin-left: 0 !important;
}
.no-padding{
    padding: 0 !important;
}
.unselect-text{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.header-quests{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-bottom: 1px solid #efefef;
}

.title-area{
    padding: 15px;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 15px;
    letter-spacing: 1.5px;
    font-weight: 900;
    text-align: center;
    color: #000;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
}

.sub-title-area{
    padding: 10px;
    margin-bottom: 15px;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    background-color: #efefef;
    border-bottom: 1px solid #efefef;
}

.title-room{
    font-size: 14px;
    cursor: pointer;
    font-weight: 100;
}

.select-title-room{
    font-size: 14px;
    cursor: pointer;
    font-weight: 900;
}

.hide-action{
    opacity: 0;
    -webkit-animation: hideAction .5s;
            animation: hideAction .5s;
}

@-webkit-keyframes hideAction {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes hideAction {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.nothing-to-do{
    color: #b4b4b4;
    margin-bottom: 10px;
}

.room-desc{
    color: #000;
    font-style: italic;
    margin-bottom: 10px;
}


.cant-access{
    color: rgb(117, 39, 39);
    /* text-decoration: line-through; */
}

.action-card{
    min-height: 40px;
    background-color: #efefef;
    border-radius: 5px;
    margin: 13px 0px;
    cursor: pointer;
    text-align: center;
    padding: 15px 20px;
}
.action-card:hover{
    box-shadow: 0px 2px 5px 1px rgba(194,194,194,1);
}

.action-card-title {
    font-family: 'Patrick Hand', cursive;
    font-weight: 600;
    margin-bottom: 7px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
}
.action-card-desc {
    font-size: 11px;
}

.showListAnim {
    -webkit-animation: opaShow 1.4s;
            animation: opaShow 1.4s;
}

.history-quests{
    padding-left: 15px;
    margin-bottom: 4px;
    opacity: 1;
    -webkit-animation: opaShow 2s;
            animation: opaShow 2s;
    display: inline-flex;
}

.history-quests-desc{
    font-style: italic;
    padding-left: 5px;
}

@-webkit-keyframes opaShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes opaShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.popup-quests{
    position: fixed;
    min-height: 50px;
    max-width: 450px;
    bottom: 15px;
    opacity: 1;
    width: 100%;
    padding: 15px;
    text-align: center;
    background-color: #000;
    left: 50%;
    transform: translate(-50%, 0);
    border: 2px solid rgb(184, 184, 184);
    color: #fff;
    z-index: 1;
    -webkit-animation: appear 0.5s;
            animation: appear 0.5s;
    box-shadow: 0px 1px 3px 1px rgba(194,194,194,0.5);
}

.popup-quests-disappear {
    -webkit-animation: disappear 0.5s;
            animation: disappear 0.5s;
    bottom: -90px;
    opacity: 1;
}

@-webkit-keyframes appear {
    0% {
        bottom: -90px;
    }
    100% {
        bottom: 15px;
    }
}

@keyframes appear {
    0% {
        bottom: -90px;
    }
    100% {
        bottom: 15px;
    }
}

@-webkit-keyframes disappear {
    0% {
        bottom: 15px;
    }
    100% {
        bottom: -90px;
    }
}

@keyframes disappear {
    0% {
        bottom: 15px;
    }
    100% {
        bottom: -90px;
    }
}

/***/
.wrapper-glitch{
    margin-top: 15px;
    margin-bottom: 15px;
}

.glitch-effect {
    text-align: center;
    color: #fff;
    font-size: 20px;
    letter-spacing: 8px;
    font-weight: 400;
    margin: 0;
    line-height: 0;
    -webkit-animation: glitch1 5s infinite;
            animation: glitch1 5s infinite;
}

  .glitch-effect:nth-child(2) {
    color: #67f3da;
    -webkit-animation: glitch2 8s infinite;
            animation: glitch2 8s infinite;
}

  .glitch-effect:nth-child(3) {
    color: #f16f6f;
    -webkit-animation: glitch3 12s infinite;
            animation: glitch3 12s infinite;
}
/*Keyframes*/

@-webkit-keyframes glitch1 {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.5deg, -0.9deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.8deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: skew(0.4deg, 1deg);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes glitch1 {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.5deg, -0.9deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.8deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: skew(0.4deg, 1deg);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: none;
        opacity: 1;
    }
}

@-webkit-keyframes glitch2 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(-2px, -3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(-5px, -2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(-5px, -1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(-2px, -6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }
}

@keyframes glitch2 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(-2px, -3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(-5px, -2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(-5px, -1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(-2px, -6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }
}

@-webkit-keyframes glitch3 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(2px, 3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(5px, 2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(5px, 1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(2px, 6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }
}

@keyframes glitch3 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(2px, 3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(5px, 2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(5px, 1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(2px, 6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }
}

/***/

.timer_unique {
    cursor: pointer;
    margin-top: 4px;
    display: inline-flex;
}

.sub_title_timer {
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    text-align: center;
}

.sub_title_timer_btn {
    position: relative;
    cursor: pointer;
    background-color: rgb(40, 40, 40);
    color: #fff;
    text-align: center;
    padding: 6px 0;
    border-radius: 0px;
}

.sub_title_timer_btn-sel {
    position: relative;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 6px 0;
    border-radius: 0px;
}

.sub_title_timer_btn-sel:hover {
    background-color: rgb(70, 70, 70);
}

.sub_title_timer_btn:hover {
    background-color: rgb(70, 70, 70);
}

.bck-abs-btn-tiles{
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: translate(calc(-50% + 1px), calc(-50% - 1px));
    color: rgba(0, 0, 0, 0);
    border: 5px solid rgba(255, 255, 255, 1);
}
.bck-abs-btn-tiles-s{
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: translate(calc(-50% + 1px), calc(-50% + 1px));
    color: rgba(0, 0, 0, 0);
    border: 0px solid rgba(255, 255, 255, 1);
}

.id_timer {
    margin-right: 10px;
    padding: 4px 7px;
    border-radius: 3px;
    background-color: #ededed;
    border: 1px solid #ddd;
}

.id_timer_done {
    margin-right: 10px;
    padding: 4px 7px;
    border-radius: 3px;
    background-color: rgba(23, 98, 61, 0.6);
    border: 1px solid rgba(23, 98, 61, 1);
}

.id_timer_toolate {
    margin-right: 10px;
    padding: 4px 7px;
    border-radius: 3px;
    background-color: rgba(98, 34, 23, 0.6);
    border: 1px solid rgb(98, 34, 23);
}

.count_timer {
    padding: 4px 7px;
}

.select-cat {
    position: absolute;
    left: 0%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.unselect-cat {
    position: absolute;
    left: -100%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.select-cat-2 {
    position: absolute;
    left: 0%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.unselect-cat-2 {
    position: absolute;
    left: 100%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel-action {
    border-top: 5px solid #000;
    padding: 20px;
    background-color: #fbfbfb;
    overflow-x: hidden;
    overflow-y: scroll;
}
.special_bg_1{
    background-color: #fbfbfb;
}
.special_bg_0-6{
    background-color: #ebebeb;
}
.special_bg_0-7{
    background-color: #ebebeb;
}
.special_bg_0-4{
    background-color: #adadad;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.btn-siv {
    background-color: #fff;
    border-radius: 3px;
    text-align: center;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    box-shadow: 0px 3px 10px 3px rgba(186,186,186,1);
}

.btn-siv:hover {
    transform: translateY(-3px);
}

.btn-siv:active {
    background-color: #000;
    color: #fff;
}

.btn-opt {
    background-color: #000;
    border-radius: 3px;
    text-align: center;
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    box-shadow: 0px 2px 5px 2px rgba(186,186,186,1);
    color: #fff;
}

.btn-opt:hover {
    transform: translateY(-3px);
}

.btn-opt:active {
    background-color: #fff;
    color: #000;
}

.input-param{
    border: 0;
    background-color: #ffffff00;
    border-bottom: 1px solid #ddd;
    padding: 8px 30px 8px 12px;
    width: 100%;
    font-weight: light;
    font-size: 12px;
    margin-top: 14px;
}

.ttt {
    display: inline;
}

.list-color-coco {
    width: 15%;
    position: relative;
}

.margin-left-coco {
    margin-left: 27.5%;
}

.size-param-coco {
    display: inline-flex;
    width: 75%;
    position: relative;
}

@media screen and (max-width: 768px) {

    .list-color-coco {
        width: 30%;
        position: relative;
    }

    .margin-left-coco {
        margin-left: 5%;
    }

    .size-param-coco {
        display: inline-flex;
        width: 90%;
        position: relative;
    }
}


.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }

.pyro > .after {
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s; }

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

.card-one-file{
    min-height: 160px;
    margin-top: 50px;
    margin-left: 0;
    font-family: Do;
    font-size: 16px;
    letter-spacing: 0.01em;
    cursor: pointer;
    color: #000;
    box-shadow: 0px 3px 6px rgb(209, 209, 209);
    padding: 0;
    border-radius: 5px;
}

.card-one-file:hover {
    transform: translateY(-4px);
}

.italic{
    font-style: italic;
}

.tags {
    font-family: Marc;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.02em;
    display: inline-flex;
    margin-top: 15px;
    font-weight: bold;
}

.bold{
    font-family: DoSemiBold;
}

.title-card{
    font-family: Marc;
    font-size: 24px;
    letter-spacing: 0.01em;
    margin-top: 8px;
    margin-bottom: 10px;
    line-height: 24px;
}

.bg-card{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
}

.content-card{
    background-color: #fff;
    padding-bottom: 20px;
}

.title-file{
    font-family: Marc;
    font-size: 30px;
    letter-spacing: 0.01em;
    margin-top: 10px;
    margin-bottom: 30px;
}

.banner-file{
    width: 100%;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.change-car-file .carousel .slide {
    background-color: transparent;
}

.change-car-file .carousel .carousel-status {
    display: none;
}

.div-content{
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 26px;
}

.label-editor {
    margin-top: 20px;
    font-family: DoMedium;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.input-editor {
    background-color: transparent;
    margin-top: 10px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    font-family: Do;
    font-size: 16px;
    letter-spacing: 0.03em;
}

.input-editor-t {
    font-family: Marc;
    font-size: 20px;
    letter-spacing: 0.01em;
}

.topic-tag{
    padding: 6px 14px 6px 18px;
    background-color: #cacaca;
    border-radius: 30px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    display: inherit;
}
.topic-tag- {
    padding: 6px 14px 6px 18px;
    background-color: #cacaca;
    border-radius: 30px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    display: inherit;
}

.topic-tag:hover {
    background-color: #000;
    color: #fff;
}

.topic-tag-select {
    background-color: #000;
    color: #fff;
}

.content-add{
    cursor: pointer;
    text-decoration: underline;
    margin-top: 35px;
}

.img-banner-ex {
    position: absolute;
    top: 90px;
    right: 15px;
    height: auto;
    width: calc(100% - 30px);
    display: none;
    z-index: 1;
    border-radius: 3px;
    border: 2px solid;
}

.img-hover:hover .img-banner-ex {
    display: block ! important;
}


.darkmode input {
    border-bottom: 1px solid rgb(110, 110, 110);
}
.darkmode textarea {
    border-bottom: 1px solid rgb(110, 110, 110);
}
.darkmode .topic-tag {
    background-color: rgb(110, 110, 110);
}
.darkmode .topic-tag- {
    background-color: rgb(110, 110, 110);
}
.darkmode .topic-tag:hover {
    background-color: rgb(0, 0, 0);
}
.darkmode .topic-tag- input::-webkit-input-placeholder {
    color: rgb(182, 182, 182);
}
.darkmode .topic-tag- input::-ms-input-placeholder {
    color: rgb(182, 182, 182);
}
.darkmode .topic-tag- input::placeholder {
    color: rgb(182, 182, 182);
}

.darkmode .topic-tag-select {
    color: #fff;
    background-color: rgb(0, 0, 0);
}

.darkmode .topic-tag-select {
    color: #fff;
}
.whitemode .topic-tag-select {
    color: #fff;
}

.darkmode div {
    color: #fff;
}
.darkmode span {
    color: #fff;
}
.darkmode a {
    color: #fff;
}
.darkmode input {
    color: #fff;
}
.darkmode textarea {
    color: #fff;
}

.whitemode div {
    color: #000;
}

.whitemode span {
    color: #000;
}

.whitemode a {
    color: #000;
}

.whitemode input {
    color: #000;
}

.whitemode textarea {
    color: #000;
}

@media screen and (max-width: 768px) {
    .font-l{
        padding-top: 4px !important;
        font-size: 14px;
        letter-spacing: 0.02em;
        line-height: 17px;
    }
    .title-card{
        font-family: Marc;
        font-size: 20px;
        letter-spacing: 0.01em;
        margin-top: 8px;
        margin-bottom: 10px;
        line-height: 22px;
    }

    .tags {
        font-size: 12px;
    }

    .change-car-file{
        padding-left: 20px;
        padding-right: 20px;
    }

    .div-content{
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 25px;
    }

    .banner-file{
        height: 250px;
    }

}

.menu-maps {
    overflow: hidden;
    height: 35px;
    width: 35px;
    border-radius: 3px;
    background-color: #F9F7F4;
    position: fixed;
    top: 2px;
    left: 2px;
    z-index: 10;
    padding: 0px 10px 10px 10px;
}

.menu-maps:hover {
    height: 335px;
    width: 350px;
    padding: 0px 10px 10px 10px;
}

.btn-control-maps {
    cursor: pointer;
    background-color: #000;
    padding: 6px 12px;
    color: #fff;
    margin-top: 5px;
}

.btn-control-maps:hover {
    background-color: rgb(165, 165, 165);
    color: #000
}

.one-tile-grind{
    display: block;
    float: left;
    /* display: inline-block */;
    cursor: pointer;
    position: relative;
}

.btn-generate{
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    padding: 6px 15px;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    white-space: pre-line;
    margin: auto;
    margin-top: 40px;
}

.btn-generate:hover{
    background-color: #000;
    color: #fff;
}

.panel-control-close{
    position: fixed;
    bottom: -315px;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 350px;
    background-color: #f3e9dc;
    width: 100%;
    padding: 0px 20px 50px 20px;
}

.panel-control-open{
    position: fixed;
    bottom: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 350px;
    background-color: #f3e9dc;
    width: 100%;
    padding: 0px 20px 50px 20px;
}

.btn-panel{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    height: 35px;
    width: calc(100% + 40px);
    cursor: pointer;
    padding-top: 5px;
    margin-left: -20px;
    padding-left: 20px;
    text-align: left;
    color: #fff;
    background-color: #4a473e;
}

.change-hexa .hexagon{
    /*fill: #fff;*/
    cursor: pointer;
}

.change-hexa-color-null { fill: red }
.change-hexa-color-volcano { fill: #6a040f }
.change-hexa-color-high_mountain { fill: #e9ecef }
.change-hexa-color-mountain { fill: #adb5bd }
.change-hexa-color-low_mountain { fill: #ccc15f }
.change-hexa-color-high_hill { fill: #bcb954 }
.change-hexa-color-hill { fill: #a5a850 }
.change-hexa-color-plain { fill: #92a54f }
.change-hexa-color-low_plain { fill: #86a049 }
.change-hexa-color-beach_coast { fill: #4d68af }
.change-hexa-color-front_coast { fill: #3b559b }
.change-hexa-color-mid_coast { fill: #293f77 }
.change-hexa-color-deep_coast { fill: #24386b }
.change-hexa-color-front_ocean { fill: #233668 }
.change-hexa-color-mid_ocean { fill: #213466 }
.change-hexa-color-deep_ocean { fill: #1d2e5b }

.tt-svg { fill: rgb(170, 82, 82); font-size: 4px }
.ttt-svg { fill: rgb(128, 0, 38); font-size: 4px }
.tttt-svg { fill: rgb(128, 0, 38); font-size: 4px }

.hover-info-params {
    display: block;
    z-index: 1;
    position: fixed;
    top: 80px;
    left: 15px;
    height: 200px;
    width: 120px;
    font-family: Do;
    font-size: 12px;
    color: #000;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    background-color: rgb(218, 218, 218);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.hover-info-params-animin {
    -webkit-animation: appear_info 0.3s ease-in forwards;
            animation: appear_info 0.3s ease-in forwards;
}

.hover-info-params-animout {
    animation: appear_info 0.3s ease-in forwards reverse;
}

@-webkit-keyframes appear_info {
    0% {
        opacity: 0;
        left: -130px;
    }
    100% {
        opacity: 1;
        left: 15px;
    }
}

@keyframes appear_info {
    0% {
        opacity: 0;
        left: -130px;
    }
    100% {
        opacity: 1;
        left: 15px;
    }
}

.cons_anim{
    -webkit-animation: opa_cons 0.3s ease-in forwards;
            animation: opa_cons 0.3s ease-in forwards;
}
@-webkit-keyframes opa_cons {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes opa_cons {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.cities-title {
    font-family: Do;
    font-size: 18px;
}

.size_event_card {
    height: 500px;
    width: 320px;
    padding: 15px 20px;
    background-color: #3d405b;
    border-radius: 5px;
    color: #fff;
    margin: auto;
    position: relative;
}

.title_event_card {
    font-family: Meri;
    border-bottom: 2px solid #fff;
    padding-bottom: 12px;
    margin-bottom: 20px;
    padding-top: 10px;
}

.btn_event_card {
    background-color: #a0513c;
    color: #fff;
    width: 150px;
    padding: 6px 15px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin: 15px auto 10px auto;
    display: inline-block;
    font-family: Do;
    font-size: 14px;
    letter-spacing: 0.1em;
}
.btn_event_card:hover {
    background-color: rgb(190, 112, 90);
}

.desc_event_card{
    margin-top: 25px;
    font-family: Marc;
    letter-spacing: 0.035em;
    text-align: left;
}

.title_desc_event_card{
    /* text-decoration: underline; */
    padding-right: 5px;
    font-weight: bold;
    color: #493813;
}
.id_event_card{
    position: absolute;
    bottom: 4px;
    right: 8px;
    color: #767cb4;
    font-size: 10px;
    letter-spacing: 0.14em;
}

.card_state_event_card {
    position: absolute;
    height: 500px;
    width: 320px;
    padding: 15px 20px;
    background-color: #4f5480;
    border-radius: 5px;
    color: #000;
    margin: auto;
    top: 0;
    left: 50%;
    /* box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2); */
    border: 1px solid rgba(0, 0, 0, 0.4);
    cursor: pointer;

}

.card_state_0_event_card {
    transform: translateX(-50%) rotateY(180deg);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card_state_0_event_card_1 {
    background-color: #3d405b;
    transform: translateX(-50%) rotateY(0deg);
}

.card_state_1_event_card {
    transform: translateX(-50%) rotateY(0deg);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card_state_1_event_card_1 {
    background-color: #3d405b;
    transform: translateX(-50%) rotateY(180deg);
}

.card_state_2_event_card {
    transform: translateX(-50%) rotateY(0deg) translateY(1000px);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card_state_2_event_card_1 {
    background-color: #3d405b;
    transform: translateX(-50%) rotateY(180deg) translateY(1000px);
}

.index_cards_position_bot{
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: Do;
    font-size: 14px;
    letter-spacing: 0.1em;
}
.click_card_center{
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    text-align: center;
    padding: 8px 0;
    font-family: Marc;
    color: #fff
}

