
.scroll-small-black::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 3px #f3f3f3;
    -moz-box-shadow: inset 0 0 3px #f3f3f3;
    box-shadow: inset 0 0 3px #f3f3f3;
	background-color: #ffffff;
}

.scroll-small-black::-webkit-scrollbar
{
	width: 3px;
    background-color: #ffffff;
}

.scroll-small-black::-webkit-scrollbar-thumb
{
	background-color: #000;
}

.row {
    margin-left: 0 !important;
}
.no-padding{
    padding: 0 !important;
}
.unselect-text{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.header-quests{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-bottom: 1px solid #efefef;
}

.title-area{
    padding: 15px;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 15px;
    letter-spacing: 1.5px;
    font-weight: 900;
    text-align: center;
    color: #000;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
}

.sub-title-area{
    padding: 10px;
    margin-bottom: 15px;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    background-color: #efefef;
    border-bottom: 1px solid #efefef;
}

.title-room{
    font-size: 14px;
    cursor: pointer;
    font-weight: 100;
}

.select-title-room{
    font-size: 14px;
    cursor: pointer;
    font-weight: 900;
}

.hide-action{
    opacity: 0;
    animation: hideAction .5s;
}

@keyframes hideAction {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.nothing-to-do{
    color: #b4b4b4;
    margin-bottom: 10px;
}

.room-desc{
    color: #000;
    font-style: italic;
    margin-bottom: 10px;
}


.cant-access{
    color: rgb(117, 39, 39);
    /* text-decoration: line-through; */
}

.action-card{
    min-height: 40px;
    background-color: #efefef;
    border-radius: 5px;
    margin: 13px 0px;
    cursor: pointer;
    text-align: center;
    padding: 15px 20px;
}
.action-card:hover{
    -webkit-box-shadow: 0px 2px 5px 1px rgba(194,194,194,1);
    -moz-box-shadow: 0px 2px 5px 1px rgba(194,194,194,1);
    box-shadow: 0px 2px 5px 1px rgba(194,194,194,1);
}

.action-card-title {
    font-family: 'Patrick Hand', cursive;
    font-weight: 600;
    margin-bottom: 7px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
}
.action-card-desc {
    font-size: 11px;
}

.showListAnim {
    animation: opaShow 1.4s;
}

.history-quests{
    padding-left: 15px;
    margin-bottom: 4px;
    opacity: 1;
    animation: opaShow 2s;
    display: inline-flex;
}

.history-quests-desc{
    font-style: italic;
    padding-left: 5px;
}

@keyframes opaShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.popup-quests{
    position: fixed;
    min-height: 50px;
    max-width: 450px;
    bottom: 15px;
    opacity: 1;
    width: 100%;
    padding: 15px;
    text-align: center;
    background-color: #000;
    left: 50%;
    transform: translate(-50%, 0);
    border: 2px solid rgb(184, 184, 184);
    color: #fff;
    z-index: 1;
    animation: appear 0.5s;
    -webkit-box-shadow: 0px 1px 3px 1px rgba(194,194,194,0.5);
    -moz-box-shadow: 0px 1px 3px 1px rgba(194,194,194,0.5);
    box-shadow: 0px 1px 3px 1px rgba(194,194,194,0.5);
}

.popup-quests-disappear {
    animation: disappear 0.5s;
    bottom: -90px;
    opacity: 1;
}

@keyframes appear {
    0% {
        bottom: -90px;
    }
    100% {
        bottom: 15px;
    }
}

@keyframes disappear {
    0% {
        bottom: 15px;
    }
    100% {
        bottom: -90px;
    }
}

/***/
.wrapper-glitch{
    margin-top: 15px;
    margin-bottom: 15px;
}

.glitch-effect {
    text-align: center;
    color: #fff;
    font-size: 20px;
    letter-spacing: 8px;
    font-weight: 400;
    margin: 0;
    line-height: 0;
    animation: glitch1 5s infinite;
}

  .glitch-effect:nth-child(2) {
    color: #67f3da;
    animation: glitch2 8s infinite;
}

  .glitch-effect:nth-child(3) {
    color: #f16f6f;
    animation: glitch3 12s infinite;
}
/*Keyframes*/

@keyframes glitch1 {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.5deg, -0.9deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.8deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: skew(0.4deg, 1deg);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes glitch2 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(-2px, -3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(-5px, -2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(-5px, -1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(-2px, -6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }
}

@keyframes glitch3 {
    0% {
        transform: none;
        opacity: 0.25;
    }
    7% {
        transform: translate(2px, 3px);
        opacity: 0.5;
    }
    10% {
        transform: none;
        opacity: 0.25;
    }
    27% {
        transform: none;
        opacity: 0.25;
    }
    30% {
        transform: translate(5px, 2px);
        opacity: 0.5;
    }
    35% {
        transform: none;
        opacity: 0.25;
    }
    52% {
        transform: none;
        opacity: 0.25;
    }
    55% {
        transform: translate(5px, 1px);
        opacity: 0.5;
    }
    50% {
        transform: none;
        opacity: 0.25;
    }
    72% {
        transform: none;
        opacity: 0.25;
    }
    75% {
        transform: translate(2px, 6px);
        opacity: 0.5;
    }
    80% {
        transform: none;
        opacity: 0.25;
    }
    100% {
        transform: none;
        opacity: 0.25;
    }
}

/***/
