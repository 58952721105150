body{
    color: #000;
    font-family: monospace;
    overflow-x: hidden;
}
.transition-50 {
    -webkit-transition: all 50ms ease-in-out;
    -moz-transition: all 50ms ease-in-out;
    -o-transition: all 50ms ease-in-out;
    transition: all 50ms ease-in-out;
}

.transition-25 {
    -webkit-transition: all 25ms ease-in-out;
    -moz-transition: all 25ms ease-in-out;
    -o-transition: all 25ms ease-in-out;
    transition: all 25ms ease-in-out;
}

.transition-100 {
    -webkit-transition: all 100ms ease-in-out;
    -moz-transition: all 100ms ease-in-out;
    -o-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
}

.transition-200 {
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

.transition-300 {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.transition-500 {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}
.transition-800 {
    -webkit-transition: all 800ms ease-in-out;
    -moz-transition: all 800ms ease-in-out;
    -o-transition: all 800ms ease-in-out;
    transition: all 800ms ease-in-out;
}
.transition-1000 {
    -webkit-transition: all 1000ms ease-in-out;
    -moz-transition: all 1000ms ease-in-out;
    -o-transition: all 1000ms ease-in-out;
    transition: all 1000ms ease-in-out;
}

.transition-250-linear {
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    transition: all 500ms linear;
}

.transition-500-linear {
    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    transition: all 500ms linear;
}

.transition-1000-linear {
    -webkit-transition: all 1000ms linear;
    -moz-transition: all 1000ms linear;
    -o-transition: all 1000ms linear;
    transition: all 1000ms linear;
}


.transition-900 {
    -webkit-transition: all 900ms ease-in-out;
    -moz-transition: all 900ms ease-in-out;
    -o-transition: all 900ms ease-in-out;
    transition: all 900ms ease-in-out;
}


.transition-1500 {
    -webkit-transition: all 1500ms ease-in-out;
    -moz-transition: all 1500ms ease-in-out;
    -o-transition: all 1500ms ease-in-out;
    transition: all 1500ms ease-in-out;
}
.no-underline-hover{
    outline: none;
}

.no-underline-hover:hover{
    text-decoration: none;
}

.center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btn-top{
    margin: 20px 15px 0 15px;
    width: fit-content;
    padding: 8px 25px;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
}

.map-params{
    border: 4px solid;
    position: relative;
    margin: 20px auto 0 auto;
}
.unique-map-area{
    position: absolute;
    border: 0px solid #ddd;
    font-size: 7px;
    text-align: center;
}

.unique-map-area:hover .hover-unique-area {
    display: block;
}

.hover-unique-area{
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    border: 2px solid;
    display: none;
    z-index: 2;
    font-size: 12px;
}

.btn-creation {
    background-color: #000;
    color: #fff;
    margin: auto;
    padding: 45px 20px;
    min-width: 300px;
    margin-top: 25px;
    width: fit-content;
    text-align: center;
    border: 2px solid #000;
}

.btn-creation:hover {
    background-color: #fff;
    color: #000;
    margin: auto;
    padding: 45px 20px;
    min-width: 300px;
    margin-top: 25px;
    width: fit-content;
    text-align: center;
    border: 2px solid rgb(93, 93, 93);
}



.start-workout{
    font-size: 5vw;
    line-height: 6.5vw;
    cursor: pointer;
    font-weight: 800;
    letter-spacing: 0.2em;
    color: #fff;
}

.close-btn-work{
    position: fixed;
    z-index: 50000;
    height: 20px;
    width: auto;
    cursor: pointer;
    top: 20px;
    right: 30px;
}

.desc-workout{
    text-transform: unset;
    font-size: 3vw;
    line-height: 4vw;
    padding: 0 100px;
    letter-spacing: 0;
    margin-top: 50px;
    font-weight: 200;
}

.hover-start:hover{
    text-decoration: underline;
}

.btn-desc-choose{
    position: fixed;
    text-align: center;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    min-width: 200px;
    font-size: 20px;
    z-index: 1000;
    cursor: pointer;
    font-weight: 100;
    text-decoration: underline;
    color: #fff;
}

.panel-choose{
    position: fixed;
    width: 400px;
    right: -400px;
    top: 0;
    background-color: #fff;
    z-index: 25010;
    padding: 20px;
}

.panel-choose-open{
    position: fixed;
    width: 400px;
    right: 0px;
    top: 0;
    background-color: #fff;
    z-index: 25010;
    padding: 20px;
}

.back-open-b{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.294);
    opacity: 1;
    z-index: 25000;
}

.back-open{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    z-index: 25000;
}
.back-close{
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
    z-index: -1;
}
.title-choose{
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}

.box-work-select{
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 3px;
    background-color: #000;
}
.box-work-unselect{
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 3px;
    background-color: #fff;
}

.close-btn-choose{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: underline;
    cursor: pointer;
}

.card-player{
    background-color: #fafafa;
    box-shadow: 1px 2px 5px rgb(182, 182, 182);
    padding: 15px 20px;
}

.change-car{
    margin-bottom: 50px;
}
.change-car .carousel.carousel-slider .control-arrow {
    padding: 0;
}
.change-car .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
}
.change-car .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
}
.change-car .carousel .slider-wrapper.axis-horizontal .slider .slide {
    padding: 0 5px 5px 5px;
}
.change-car .carousel .slide {
    background-color: #fff;
}
.change-car .carousel .carousel-status {
    text-shadow: unset !important;
    top: 10px !important;
    right: 10px !important;
    font-weight: bold !important;
    color: #000  !important;
}
.select-yam{
    width: 60px;
    border: 0;
    text-align: right;
    background-color: #ffffff00;
}

@media screen and (max-width: 768px) {
    .start-workout{
        font-size: 7vw;
        line-height: 8.5vw;
        cursor: pointer;
        font-weight: 800;
        letter-spacing: 0.2em;
        color: #fff;
    }

    .no-padding-mobile{
        padding: 0 !important;
    }
    .desc-workout{
        text-transform: unset;
        font-size: 4.5vw;
        line-height: 6.5vw;
        letter-spacing: 0;
        padding: 0 0px;
        margin-top: 40px;
        font-weight: 200;
    }
    .btn-desc-choose{
        width: 100%;
    }
    .panel-choose-open{
        width: 100%;
        right: 0;
    }
    .panel-choose{
        width: 100%;
        right: -100%;
    }

    .close-btn-choose{
        bottom: 30px;
    }
}
