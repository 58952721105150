
@font-face {
	font-family: "Meri";
	src: url("./fonts/MeriendaOne-Regular.ttf");
}

@font-face {
	font-family: "Marc";
	src: url("./fonts/MarcellusSC-Regular.ttf");
}


@font-face {
	font-family: "DoLight";
	src: url("./fonts/Dosis-Light.ttf");
}

@font-face {
	font-family: "Do";
	src: url("./fonts/Dosis-Regular.ttf");
}

@font-face {
	font-family: "DoMedium";
	src: url("./fonts/Dosis-Medium.ttf");
}

@font-face {
	font-family: "DoSemiBold";
	src: url("./fonts/Dosis-SemiBold.ttf");
}

@font-face {
	font-family: "SP";
	src: url("./fonts/SourceSansPro-Regular.ttf");
}

@font-face {
	font-family: "SPLight";
	src: url("./fonts/SourceSansPro-Light.ttf");
}

@font-face {
	font-family: "SPSemi";
	src: url("./fonts/SourceSansPro-SemiBold.ttf");
}
