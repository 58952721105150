.timer_unique {
    cursor: pointer;
    margin-top: 4px;
    display: inline-flex;
}

.sub_title_timer {
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    text-align: center;
}

.sub_title_timer_btn {
    position: relative;
    cursor: pointer;
    background-color: rgb(40, 40, 40);
    color: #fff;
    text-align: center;
    padding: 6px 0;
    border-radius: 0px;
}

.sub_title_timer_btn-sel {
    position: relative;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 6px 0;
    border-radius: 0px;
}

.sub_title_timer_btn-sel:hover {
    background-color: rgb(70, 70, 70);
}

.sub_title_timer_btn:hover {
    background-color: rgb(70, 70, 70);
}

.bck-abs-btn-tiles{
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: translate(calc(-50% + 1px), calc(-50% - 1px));
    color: rgba(0, 0, 0, 0);
    border: 5px solid rgba(255, 255, 255, 1);
}
.bck-abs-btn-tiles-s{
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: translate(calc(-50% + 1px), calc(-50% + 1px));
    color: rgba(0, 0, 0, 0);
    border: 0px solid rgba(255, 255, 255, 1);
}

.id_timer {
    margin-right: 10px;
    padding: 4px 7px;
    border-radius: 3px;
    background-color: #ededed;
    border: 1px solid #ddd;
}

.id_timer_done {
    margin-right: 10px;
    padding: 4px 7px;
    border-radius: 3px;
    background-color: rgba(23, 98, 61, 0.6);
    border: 1px solid rgba(23, 98, 61, 1);
}

.id_timer_toolate {
    margin-right: 10px;
    padding: 4px 7px;
    border-radius: 3px;
    background-color: rgba(98, 34, 23, 0.6);
    border: 1px solid rgb(98, 34, 23);
}

.count_timer {
    padding: 4px 7px;
}

.select-cat {
    position: absolute;
    left: 0%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.unselect-cat {
    position: absolute;
    left: -100%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.select-cat-2 {
    position: absolute;
    left: 0%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.unselect-cat-2 {
    position: absolute;
    left: 100%;
    width: calc(100% - 40px);
    margin: 0 20px;
    min-height: 400px;
    padding: 10px;
    background-color: rgb(240, 240, 240);
    border: 2px solid rgb(220, 220, 220);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
