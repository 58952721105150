.size_event_card {
    height: 500px;
    width: 320px;
    padding: 15px 20px;
    background-color: #3d405b;
    border-radius: 5px;
    color: #fff;
    margin: auto;
    position: relative;
}

.title_event_card {
    font-family: Meri;
    border-bottom: 2px solid #fff;
    padding-bottom: 12px;
    margin-bottom: 20px;
    padding-top: 10px;
}

.btn_event_card {
    background-color: #a0513c;
    color: #fff;
    width: 150px;
    padding: 6px 15px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin: 15px auto 10px auto;
    display: inline-block;
    font-family: Do;
    font-size: 14px;
    letter-spacing: 0.1em;
}
.btn_event_card:hover {
    background-color: rgb(190, 112, 90);
}

.desc_event_card{
    margin-top: 25px;
    font-family: Marc;
    letter-spacing: 0.035em;
    text-align: left;
}

.title_desc_event_card{
    /* text-decoration: underline; */
    padding-right: 5px;
    font-weight: bold;
    color: #493813;
}
.id_event_card{
    position: absolute;
    bottom: 4px;
    right: 8px;
    color: #767cb4;
    font-size: 10px;
    letter-spacing: 0.14em;
}

.card_state_event_card {
    position: absolute;
    height: 500px;
    width: 320px;
    padding: 15px 20px;
    background-color: #4f5480;
    border-radius: 5px;
    color: #000;
    margin: auto;
    top: 0;
    left: 50%;
    /* box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2); */
    border: 1px solid rgba(0, 0, 0, 0.4);
    cursor: pointer;

}

.card_state_0_event_card {
    transform: translateX(-50%) rotateY(180deg);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card_state_0_event_card_1 {
    background-color: #3d405b;
    transform: translateX(-50%) rotateY(0deg);
}

.card_state_1_event_card {
    transform: translateX(-50%) rotateY(0deg);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card_state_1_event_card_1 {
    background-color: #3d405b;
    transform: translateX(-50%) rotateY(180deg);
}

.card_state_2_event_card {
    transform: translateX(-50%) rotateY(0deg) translateY(1000px);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card_state_2_event_card_1 {
    background-color: #3d405b;
    transform: translateX(-50%) rotateY(180deg) translateY(1000px);
}

.index_cards_position_bot{
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: Do;
    font-size: 14px;
    letter-spacing: 0.1em;
}
.click_card_center{
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    text-align: center;
    padding: 8px 0;
    font-family: Marc;
    color: #fff
}
