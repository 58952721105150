.panel-action {
    border-top: 5px solid #000;
    padding: 20px;
    background-color: #fbfbfb;
    overflow-x: hidden;
    overflow-y: scroll;
}
.special_bg_1{
    background-color: #fbfbfb;
}
.special_bg_0-6{
    background-color: #ebebeb;
}
.special_bg_0-7{
    background-color: #ebebeb;
}
.special_bg_0-4{
    background-color: #adadad;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.btn-siv {
    background-color: #fff;
    border-radius: 3px;
    text-align: center;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    -webkit-box-shadow: 0px 3px 10px 3px rgba(186,186,186,1);
    -moz-box-shadow: 0px 3px 10px 3px rgba(186,186,186,1);
    box-shadow: 0px 3px 10px 3px rgba(186,186,186,1);
}

.btn-siv:hover {
    transform: translateY(-3px);
}

.btn-siv:active {
    background-color: #000;
    color: #fff;
}

.btn-opt {
    background-color: #000;
    border-radius: 3px;
    text-align: center;
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    -webkit-box-shadow: 0px 2px 5px 2px rgba(186,186,186,1);
    -moz-box-shadow: 0px 2px 5px 2px rgba(186,186,186,1);
    box-shadow: 0px 2px 5px 2px rgba(186,186,186,1);
    color: #fff;
}

.btn-opt:hover {
    transform: translateY(-3px);
}

.btn-opt:active {
    background-color: #fff;
    color: #000;
}

.input-param{
    border: 0;
    background-color: #ffffff00;
    border-bottom: 1px solid #ddd;
    padding: 8px 30px 8px 12px;
    width: 100%;
    font-weight: light;
    font-size: 12px;
    margin-top: 14px;
}

.ttt {
    display: inline;
}

.list-color-coco {
    width: 15%;
    position: relative;
}

.margin-left-coco {
    margin-left: 27.5%;
}

.size-param-coco {
    display: inline-flex;
    width: 75%;
    position: relative;
}

@media screen and (max-width: 768px) {

    .list-color-coco {
        width: 30%;
        position: relative;
    }

    .margin-left-coco {
        margin-left: 5%;
    }

    .size-param-coco {
        display: inline-flex;
        width: 90%;
        position: relative;
    }
}
