.card-one-file{
    min-height: 160px;
    margin-top: 50px;
    margin-left: 0;
    font-family: Do;
    font-size: 16px;
    letter-spacing: 0.01em;
    cursor: pointer;
    color: #000;
    box-shadow: 0px 3px 6px rgb(209, 209, 209);
    padding: 0;
    border-radius: 5px;
}

.card-one-file:hover {
    transform: translateY(-4px);
}

.italic{
    font-style: italic;
}

.tags {
    font-family: Marc;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.02em;
    display: inline-flex;
    margin-top: 15px;
    font-weight: bold;
}

.bold{
    font-family: DoSemiBold;
}

.title-card{
    font-family: Marc;
    font-size: 24px;
    letter-spacing: 0.01em;
    margin-top: 8px;
    margin-bottom: 10px;
    line-height: 24px;
}

.bg-card{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
}

.content-card{
    background-color: #fff;
    padding-bottom: 20px;
}

.title-file{
    font-family: Marc;
    font-size: 30px;
    letter-spacing: 0.01em;
    margin-top: 10px;
    margin-bottom: 30px;
}

.banner-file{
    width: 100%;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.change-car-file .carousel .slide {
    background-color: transparent;
}

.change-car-file .carousel .carousel-status {
    display: none;
}

.div-content{
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 26px;
}

.label-editor {
    margin-top: 20px;
    font-family: DoMedium;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.input-editor {
    background-color: transparent;
    margin-top: 10px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    font-family: Do;
    font-size: 16px;
    letter-spacing: 0.03em;
}

.input-editor-t {
    font-family: Marc;
    font-size: 20px;
    letter-spacing: 0.01em;
}

.topic-tag{
    padding: 6px 14px 6px 18px;
    background-color: #cacaca;
    border-radius: 30px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    display: inherit;
}
.topic-tag- {
    padding: 6px 14px 6px 18px;
    background-color: #cacaca;
    border-radius: 30px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    display: inherit;
}

.topic-tag:hover {
    background-color: #000;
    color: #fff;
}

.topic-tag-select {
    background-color: #000;
    color: #fff;
}

.content-add{
    cursor: pointer;
    text-decoration: underline;
    margin-top: 35px;
}

.img-banner-ex {
    position: absolute;
    top: 90px;
    right: 15px;
    height: auto;
    width: calc(100% - 30px);
    display: none;
    z-index: 1;
    border-radius: 3px;
    border: 2px solid;
}

.img-hover:hover .img-banner-ex {
    display: block ! important;
}


.darkmode input {
    border-bottom: 1px solid rgb(110, 110, 110);
}
.darkmode textarea {
    border-bottom: 1px solid rgb(110, 110, 110);
}
.darkmode .topic-tag {
    background-color: rgb(110, 110, 110);
}
.darkmode .topic-tag- {
    background-color: rgb(110, 110, 110);
}
.darkmode .topic-tag:hover {
    background-color: rgb(0, 0, 0);
}
.darkmode .topic-tag- input::placeholder {
    color: rgb(182, 182, 182);
}

.darkmode .topic-tag-select {
    color: #fff;
    background-color: rgb(0, 0, 0);
}

.darkmode .topic-tag-select {
    color: #fff;
}
.whitemode .topic-tag-select {
    color: #fff;
}

.darkmode div {
    color: #fff;
}
.darkmode span {
    color: #fff;
}
.darkmode a {
    color: #fff;
}
.darkmode input {
    color: #fff;
}
.darkmode textarea {
    color: #fff;
}

.whitemode div {
    color: #000;
}

.whitemode span {
    color: #000;
}

.whitemode a {
    color: #000;
}

.whitemode input {
    color: #000;
}

.whitemode textarea {
    color: #000;
}

@media screen and (max-width: 768px) {
    .font-l{
        padding-top: 4px !important;
        font-size: 14px;
        letter-spacing: 0.02em;
        line-height: 17px;
    }
    .title-card{
        font-family: Marc;
        font-size: 20px;
        letter-spacing: 0.01em;
        margin-top: 8px;
        margin-bottom: 10px;
        line-height: 22px;
    }

    .tags {
        font-size: 12px;
    }

    .change-car-file{
        padding-left: 20px;
        padding-right: 20px;
    }

    .div-content{
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 25px;
    }

    .banner-file{
        height: 250px;
    }

}
