.menu-maps {
    overflow: hidden;
    height: 35px;
    width: 35px;
    border-radius: 3px;
    background-color: #F9F7F4;
    position: fixed;
    top: 2px;
    left: 2px;
    z-index: 10;
    padding: 0px 10px 10px 10px;
}

.menu-maps:hover {
    height: 335px;
    width: 350px;
    padding: 0px 10px 10px 10px;
}

.btn-control-maps {
    cursor: pointer;
    background-color: #000;
    padding: 6px 12px;
    color: #fff;
    margin-top: 5px;
}

.btn-control-maps:hover {
    background-color: rgb(165, 165, 165);
    color: #000
}

.one-tile-grind{
    display: block;
    float: left;
    /* display: inline-block */;
    cursor: pointer;
    position: relative;
}

.btn-generate{
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    padding: 6px 15px;
    cursor: pointer;
    width: fit-content;
    white-space: pre-line;
    margin: auto;
    margin-top: 40px;
}

.btn-generate:hover{
    background-color: #000;
    color: #fff;
}

.panel-control-close{
    position: fixed;
    bottom: -315px;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 350px;
    background-color: #f3e9dc;
    width: 100%;
    padding: 0px 20px 50px 20px;
}

.panel-control-open{
    position: fixed;
    bottom: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 350px;
    background-color: #f3e9dc;
    width: 100%;
    padding: 0px 20px 50px 20px;
}

.btn-panel{
    position: sticky;
    top: 0;
    left: 0;
    height: 35px;
    width: calc(100% + 40px);
    cursor: pointer;
    padding-top: 5px;
    margin-left: -20px;
    padding-left: 20px;
    text-align: left;
    color: #fff;
    background-color: #4a473e;
}

.change-hexa .hexagon{
    /*fill: #fff;*/
    cursor: pointer;
}

.change-hexa-color-null { fill: red }
.change-hexa-color-volcano { fill: #6a040f }
.change-hexa-color-high_mountain { fill: #e9ecef }
.change-hexa-color-mountain { fill: #adb5bd }
.change-hexa-color-low_mountain { fill: #ccc15f }
.change-hexa-color-high_hill { fill: #bcb954 }
.change-hexa-color-hill { fill: #a5a850 }
.change-hexa-color-plain { fill: #92a54f }
.change-hexa-color-low_plain { fill: #86a049 }
.change-hexa-color-beach_coast { fill: #4d68af }
.change-hexa-color-front_coast { fill: #3b559b }
.change-hexa-color-mid_coast { fill: #293f77 }
.change-hexa-color-deep_coast { fill: #24386b }
.change-hexa-color-front_ocean { fill: #233668 }
.change-hexa-color-mid_ocean { fill: #213466 }
.change-hexa-color-deep_ocean { fill: #1d2e5b }

.tt-svg { fill: rgb(170, 82, 82); font-size: 4px }
.ttt-svg { fill: rgb(128, 0, 38); font-size: 4px }
.tttt-svg { fill: rgb(128, 0, 38); font-size: 4px }

.hover-info-params {
    display: block;
    z-index: 1;
    position: fixed;
    top: 80px;
    left: 15px;
    height: 200px;
    width: 120px;
    font-family: Do;
    font-size: 12px;
    color: #000;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    background-color: rgb(218, 218, 218);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.hover-info-params-animin {
    animation: appear_info 0.3s ease-in forwards;
}

.hover-info-params-animout {
    animation: appear_info 0.3s ease-in forwards reverse;
}

@keyframes appear_info {
    0% {
        opacity: 0;
        left: -130px;
    }
    100% {
        opacity: 1;
        left: 15px;
    }
}

.cons_anim{
    animation: opa_cons 0.3s ease-in forwards;
}
@keyframes opa_cons {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
